import { extendTheme } from '@chakra-ui/react';

// Global style overrides
import { styles } from './styles';
import { fonts } from './fonts';
// Foundational style overrides
//TODO
// import borders from './foundations/borders';
// Component style overrides
import { Link } from './components';

const theme = extendTheme({
  styles,
  fonts,
  components: {
    Link,
  },
});

export default theme;
