/**
 *
 * global styles
 */
export const styles = {
  global: {
    // styles for the `body`
    global: () => ({
      body: {},
    }),
  },
};
